<template>
    <b-row>
      <b-col>
        <b-card>
          <loading
            :active.sync="isLoading"
            :is-full-page="false"
            :color="this.$config.LOADER.color"
            :opacity="this.$config.LOADER.opacity"
            :background-color="this.$config.LOADER.backgroundColor"
            :z-index="10000"
          ></loading>
          <div class="mb-3">
            <download-excel
              class="btn btn-primary pull-right ml-3"
              :fetch="fetchData"
              :fields="download.header"
              :before-generate="startDownload"
              :before-finish="finishDownload"
              :name="'Зээлийн түүх.xls'"
              v-if="items != undefined && items.length > 0"
            >
              <b-spinner small type="grow" v-show="download.loading"></b-spinner>
              <span v-show="download.loading" class="ml-1">Уншиж байна</span>
              <i class="fa fa-download" v-show="!download.loading"></i>
              <span v-show="!download.loading" class="ml-1">Excel татах</span>
            </download-excel>
  
            <div class="pull-right">
              <b-form-select
                v-model="query.per_page"
                :options="[5, 10, 25, 100]"
                v-if="items != undefined && items.length > 0"
              >
              </b-form-select>
            </div>
            <b-row>
              <b-col
                class="col-md-3"
                :class="{ 'pl-0': items != undefined && items.length > 0 }"
              >
                <multiselect
                  v-model="selected_employee"
                  :options="employees"
                  :searchable="true"
                  placeholder="Ажилтан"
                  :close-on-select="true"
                  :option-height="10"
                  track-by="id"
                >
                  <template slot="singleLabel" slot-scope="props">
                    @{{ props.option.firstname }}
                  </template>
                  <template slot="option" slot-scope="props">
                    @{{ props.option.firstname }}
                  </template>
                </multiselect>
              </b-col>
              <b-col class="col-md-3">
                <multiselect
                  v-model="selected_merchant"
                  :options="merchants"
                  :searchable="true"
                  placeholder="Merchant"
                  :close-on-select="true"
                  :option-height="10"
                  track-by="id"
                >
                  <template slot="singleLabel" slot-scope="props">
                    {{ props.option.code + " | " + props.option.name }}
                  </template>
                  <template slot="option" slot-scope="props">
                    {{ props.option.code + " | " + props.option.name }}
                  </template>
                </multiselect>
              </b-col>
              <b-col class="col-md-4">
                <b-form @submit.stop.prevent="submitSearch">
                  <b-input-group>
                    <b-input-group-prepend is-text
                      ><i class="fa fa-search"></i
                    ></b-input-group-prepend>
                    <b-form-input
                      v-model="search"
                      placeholder="Хайх"
                    ></b-form-input>
                    <b-input-group-append
                      ><b-button variant="primary" type="submit"
                        >Хайх</b-button
                      ></b-input-group-append
                    >
                    <b-input-group-append
                      ><b-button variant="secondary" @click="loadData"
                        ><i class="fa fa-refresh"></i
                      ></b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form>
              </b-col>
            </b-row>
          </div>
          <div
            class="table-responsive"
            v-if="items != undefined && items.length > 0"
          >
            <b-table
              id="filter-table"
              stacked="md"
              @sort-changed="sortingChanged"
              hover
              bordered
              small
              responsive="sm"
              :items="items"
              :fields="columns"
              no-local-sorting
            >
              <template v-slot:cell(index)="data">
                <span v-if="data.index <= pagination.to - pagination.from">{{
                  data.index + pagination.from
                }}</span>
              </template>
              <template v-slot:cell(accountNumber)="data">
                {{ data.item.accountNumber }}<br />
                <small variant="info" class="text-muted">{{
                  data.item.merchantName
                }}</small>
              </template>
              <template v-slot:cell(parent)="data">
                {{
                  data.item.hasOwnProperty("parent") ? data.item.parent.name : ""
                }}
              </template>
              <template v-slot:cell(nicename)="data">
                {{ data.item.lastname.substr(0, 1) + "." + data.item.firstname }}
              </template>
              <template v-slot:cell(days)="data">
                <strong v-if="data.index <= pagination.to - pagination.from">
                  {{ data.item.days }} өдөр</strong
                >
              </template>
              <template v-slot:cell(isClosed)="data">
                <b-badge variant="success" v-if="data.item.isClosed"
                  >Тийм</b-badge
                >
                <b-badge variant="danger" v-else-if="data.item.isClosed != null"
                  >Үгүй</b-badge
                >
              </template>
              <template v-slot:cell(term)="data">
                <span v-if="data.index <= pagination.to - pagination.from">{{
                  data.item.term
                }}</span>
                <strong v-else class="pull-right">{{ data.item.term }}</strong>
              </template>
              <template v-slot:cell(closingAmount)="data">
                <b-link
                  v-if="!data.item.isClosed"
                  v-b-modal="'modal-' + data.index"
                  >{{ data.item.closingAmount }}</b-link
                >
                <b-modal :id="'modal-' + data.index" title="Задаргаа" hide-footer>
                  <template slot="default" class="mt-5">
                    <b-list-group class="text-left" v-if="data.item.loanBalance">
                      <b-list-group-item
                        >Үндсэн зээл
                        <span class="pull-right text-primary"
                          >{{ data.item.loanBalance }}₮</span
                        ></b-list-group-item
                      >
                      <b-list-group-item
                        >Хуримтлагдсан хүү
                        <span class="pull-right text-primary"
                          >{{ data.item.interestAmount }}₮</span
                        ></b-list-group-item
                      >
                      <b-list-group-item
                        >Олголтын шимтгэл
                        <span class="pull-right text-primary"
                          >{{ data.item.takeFeeAmount }}₮</span
                        ></b-list-group-item
                      >
                      <b-list-group-item
                        >Алданги
                        <span class="pull-right text-primary"
                          >{{ data.item.unduelossAmount }}₮</span
                        ></b-list-group-item
                      >
                      <b-list-group-item
                        >Өдрийн хүү
                        <span class="pull-right text-primary"
                          >{{
                            parseFloat(
                              (parseFloat(
                                data.item.loanBalance.replace(/,/g, "")
                              ) *
                                data.item.dayInterest) /
                                100
                            ) | currecry
                          }}₮</span
                        ></b-list-group-item
                      >
                      <b-list-group-item
                        >Нэмэгдүүлсэн хүү
                        <span class="pull-right text-primary"
                          >{{
                            parseFloat(
                              data.item.undueLossInterest
                                ? (parseFloat(
                                    data.item.loanBalance.replace(/,/g, "")
                                  ) *
                                    data.item.undueLossInterest) /
                                    100
                                : "0"
                            ) | currecry
                          }}₮</span
                        ></b-list-group-item
                      >
                    </b-list-group>
                  </template>
                </b-modal>
              </template>
              <template v-slot:cell(actions)="data">
                <b-button-group
                  v-if="data.index <= pagination.to - pagination.from"
                >
                  <b-button
                    size="sm"
                    variant="primary"
                    :to="{
                      name: 'admin.customer-loan-accounts.detail',
                      params: { id: data.item.id },
                    }"
                  >
                    <i class="fa fa-id-card"></i>
                  </b-button>
                </b-button-group>
              </template>
              <template v-slot:cell(status)="data">
                <b-badge variant="success" v-if="data.item.status == 'SUCCESS'">{{
                  data.item.status
                }}</b-badge>
                <b-badge
                  variant="primary"
                  v-else-if="data.item.status == 'NEW'"
                  >{{ data.item.status }}</b-badge
                >
                <b-badge variant="danger" v-else>{{ data.item.status }}</b-badge>
              </template>
  
              <template v-slot:cell(typeCode)="data">
                {{ $store.getters.loan_type[data.item.typeCode] }}<br />
                <small
                  variant="info"
                  class="text-muted"
                  v-if="data.item.logCreatedBy"
                  >@{{ data.item.logCreatedBy }}</small
                >
              </template>
            </b-table>
  
            <div slot="footer" class="mb-0 mt-3">
              <nav class="pull-right" v-if="pagination.total > query.per_page">
                <b-pagination
                  class="mb-0"
                  :total-rows="pagination.total"
                  :per-page="query.per_page"
                  v-model="query.page"
                />
              </nav>
              <p class="mb-0">
                {{ pagination.total }} өгөгдлөөс
                {{ pagination.to ? pagination.from : 0 }} -
                {{ pagination.to }} өгөгдөл харагдаж байна.
              </p>
            </div>
          </div>
  
          <b-alert variant="warning" v-else show class="mb-0">
            Мэдээлэл байхгүй байна
          </b-alert>
        </b-card>
      </b-col>
    </b-row>
  </template>
  
  <script>
  import moment from "moment";
  import axios from "axios";
  export default {
    name: "Customers.loanAccountHistory",
    components: { axios },
    props: ["customerId"],
    data: function() {
      return {
        isLoading: false,
        filterShow: false,
        isOverduePayment: false,
        query: {
          id: 0,
          page: 1,
          per_page: 10,
          orderDirection: "DESC",
          orderBy: "loan_date",
          search: "",
          columns: "",
          created_by: null,
          merchant_id: null,
        },
        payment: {
          over: "0",
          under: "0",
          undueLoss: "0",
        },
        pagination: {
          total: 0,
          from: 0,
          to: 0,
        },
        orderDesc: false,
        items: [],
        selected_merchant: null,
        selected_employee: null,
        merchants: [],
        employees: [],
        search: "",
        download: {
          loading: false,
          header: {},
        },
        columns: [
          { label: "№", key: "index", class: "text-center" },
          {
            label: "Төрөл",
            key: "typeCode",
            sortable: true,
            sort_key: "type_code",
          },
          { label: "Данс", key: "accountNumber" },
          {
            label: "Овог",
            key: "lastname",
            sortable: true,
            sort_key: "lastname",
          },
          {
            label: "Нэр",
            key: "firstname",
            sortable: true,
            sort_key: "firstname",
          },
          {
            label: "Регистр",
            key: "register",
            sortable: true,
            sort_key: "register",
          },
          {
            label: "Утас",
            key: "phoneNumber",
            sortable: true,
            sort_key: "phone",
          },
          {
            label: "Зээлийн лимит",
            key: "limit",
            sortable: true,
            sort_key: "loan_limit",
            class: "text-right",
          },
          {
            label: "Зээлсэн дүн",
            key: "loanAmount",
            sortable: true,
            sort_key: "loan_amount",
            class: "text-right",
          },
          {
            label: "Үлдэгдэл",
            key: "loanBalance",
            sortable: true,
            sort_key: "loan_balance",
            class: "text-right",
          },
          {
            label: "Зээл авсан огноо",
            key: "loanDate",
            sortable: true,
            sort_key: "loan_date",
          },
          { label: "Хүү", key: "interest", sortable: true, sort_key: "interest" },
          { label: "Хугацаа", key: "term", sortable: true, sort_key: "term" },
          {
            label: "Сарын төлөлт",
            key: "monthlyPayment",
            sortable: true,
            sort_key: "monthly_payment",
            class: "text-right",
          },
          {
            label: "Дуусах огноо",
            key: "expiryDate",
            sortable: true,
            sort_key: "expiry_date",
          },
          { label: "Үйлдэл", key: "actions", class: "text-center" },
        ],
        isActiveLoan: false,
      };
    },
    created: function() {
      this.initData();
      this.$data.query.columns = [
        "firstname",
        "lastname",
        "register",
        "phone",
        "loan_limit",
        "loan_date",
        "loan_amount",
        "loan_balance",
        "interest",
        "term",
        "monthly_payment",
        "expiry_date",
      ].join(",");
      this.$data.query.id = this.customerId;
      this.$data.download.header = {};
      this.$data.download.header["Merchant"] = "merchantName";
      this.$data.download.header["Бүртгэсэн ажилтан"] = "logCreatedBy";
      for (let i in this.$data.columns) {
        let _field = this.$data.columns[i];
        if (_field.label != "Үйлдэл")
          this.$data.download.header[_field.label] = _field.key;
      }
      this.$data.download.header['Илүү төлөлт'] = 'overpaymentAmount'
      this.$data.download.header['Дараагийн төлөлт хийх өдөр'] = 'paymentDate'
  
      this.loadData();
    },
    watch: {
      query: {
        handler(query) {
          this.loadData();
        },
        deep: true,
      },
      selected_merchant: {
        handler(selected_merchant) {
          if (selected_merchant)
            this.$data.query.merchant_id = selected_merchant.id;
          else this.$data.query.merchant_id = null;
          this.loadData();
        },
        deep: true,
      },
      selected_employee: {
        handler(selected_employee) {
          if (selected_employee)
            this.$data.query.created_by = selected_employee.id;
          else this.$data.query.created_by = null;
          this.loadData();
        },
        deep: true,
      },
    },
    methods: {
      initData: function() {
        this.$http
          .get(this.$config.API_URL + "PurchaseWebService/get_merchant_list", {
            params: {
              page: 1,
              per_page: 0,
              orderBy: "code",
              columns: [
                "code",
                "name",
                "register_number",
                "start_date",
                "end_date",
                "create_date",
              ].join(","),
            },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          })
          .then(
            (response) => {
              //console.log(response)
              this.isLoading = false;
              this.$data.merchants = response.body.data.filter(
                (item) => item.isActive
              );
            },
            (response) => {
              this.isLoading = false;
            }
          );
  
        this.$http
          .get(this.$config.API_URL + "AdminWebService/get_loan_employee_list", {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          })
          .then(
            (response) => {
              this.$data.employees = response.body.data;
            },
            (response) => {}
          );
      },
      loadData: function() {
        this.checkSession();
        this.isLoading = true;
        this.$http
          .get(this.$config.API_URL + "PurchaseWebService/get_loan_list", {
            params: this.$data.query,
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          })
          .then(
            (response) => {
              this.isLoading = false;
              this.$data.items = response.body.data;
              this.$data.pagination.total = response.body.total;
              this.$data.pagination.from = response.body.from;
              this.$data.pagination.to = response.body.to;
            },
            (response) => {
              this.isLoading = false;
            }
          );
      },
      sortingChanged(ctx) {
        let _field = "";
        for (let i in this.fields) {
          if (this.fields[i].key == ctx.sortBy) {
            this.$data.query.orderBy = this.fields[i].sort_key;
            break;
          }
        }
        this.$data.query.orderDirection = ctx.sortDesc ? "ASC" : "DESC";
      },
      submitSearch: function() {
        this.$data.query.search = this.$data.search;
      },
      async fetchData() {
        if (this.download.loading) {
          this.download.loading = true;
          const response = await axios.get(
            this.$config.API_URL + "PurchaseWebService/get_loan_list",
            {
              params: {
                page: 1,
                per_page: 0,
                orderDirection: this.$data.query.orderDirection,
                orderBy: this.$data.query.orderBy,
                search: this.$data.query.search,
                columns: this.$data.query.columns,
                created_by: this.$data.query.created_by,
                merchant_id: this.$data.query.merchant_id,
                id: this.$data.query.id,
              },
              headers: this.$store.getters.httpHeader,
              emulateJSON: true,
            }
          );
          if (response.data.status == 500) {
            this.showToast(
              "Алдаа",
              "Алдаа гарсан байна. Дахин оролдоно уу",
              "danger"
            );
            this.download.loading = false;
            return [];
          }
  
          let _result = [];
          for (let i in response.data.data) {
            let _item = response.data.data[i];
            _item.index = parseInt(i) + 1;
            _item.closingAmount = "";
            _result.push(_item);
          }
          return _result;
        }
        return [];
      },
      startDownload() {
        this.download.loading = true;
      },
      finishDownload() {
        this.download.loading = false;
      },
    },
  };
  </script>
  